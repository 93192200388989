import { render, staticRenderFns } from "./PDFViewer.vue?vue&type=template&id=27e28070&scoped=true"
import script from "./PDFViewer.vue?vue&type=script&lang=js"
export * from "./PDFViewer.vue?vue&type=script&lang=js"
import style0 from "./PDFViewer.vue?vue&type=style&index=0&id=27e28070&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e28070",
  null
  
)

export default component.exports